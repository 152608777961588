import { productsLogo, productsDiagrams } from "routes/picPath";

const constContent = {
  pageTitle: "Produkty",
  pageSubTitle: "w ramach Platformy",
  btn: "więcej o produkcie",
  prev: "poprzedni",
  next: "następny",
};

const products = [
  {
    logo: {
      src: productsLogo.gpt,
      alt: "logo gpt",
    },
    fileName: "HyperView GPT.pdf",
    name: {
      short: "gpt",
      superShort: "gpt",
      extended: "Geoportal Toolkit",
    },
    shortNote:
      "Aplikacja mapowa w przeglądarce WWW. Nowoczesny geoportal dla pracowników urzędów oraz mieszkańców.",
    pic: {
      src: productsDiagrams.gptPic,
      alt: "diagram gpt",
    },
    paragraphs: [
      "Rozbudowane, funkcjonalne narzędzie działające w przeglądarce WWW",
      "Zawiera ponad 120 funkcji",
      "Umożliwia tworzenia portali tematycznych (mieszkańca, inwestora, wyborczy) ściśle dostosowanych do charakteru publikowanych informacji",
      "Prezentuje aktualne dane w czasie rzeczywistym",
      "Pozwala na integrację z wieloma źródłami danych zgodnie z otwartymi standardami",
      "Ułatwia publikowanie Otwartych Danych do wykorzystania przez mieszkańców",
      "Daje możliwość ścisłego dostosowania wyglądu i funkcjonalności do potrzeb użytkowników",
      "Udostępnia dane w wygodny i bezpieczny sposób",
      "Dostępna wersja dla słabowidzących",
    ],
  },
  {
    logo: {
      src: productsLogo.isdp,
      alt: "logo isdp",
    },
    fileName: "HyperView ISDP.pdf",
    name: {
      short: "isdp",
      superShort: "isdp",
      extended: "Internetowy Serwer Danych Przestrzennych",
    },
    shortNote:
      "Jednolite i uporządkowane dane stanowią podstawę właściwych procesów zarządzania. Serwer ISDP jest centralnym elementem platformy usług IUIP.",
    pic: {
      src: productsDiagrams.isdpPic,
      alt: "diagram isdp",
    },
    paragraphs: [
      "Serwer danych przestrzennych stanowi podstawę sieci usług oraz mikrousług, realizujących różnorodne działania na danych z lokalizacją przestrzenną. Poszczególne usługi mogą być rozwijane przez HyperView lub firmy zewnętrzne",
      "Integruje i przetwarza dane przestrzenne pochodzące z wielu źródeł, prezentuje je na mapie oraz udostępnia zewnętrznym aplikacjom",
      "Pełni rolę centralnej hurtowni danych przestrzennych, w której zgromadzona jest pełnia wiedzy o zlokalizowanych geograficznie obiektach",
    ],
  },
  {
    logo: {
      src: productsLogo.sowa,
      alt: "logo sowa",
    },
    fileName: "HyperView SOWA.pdf",
    name: {
      short: "sowa",
      superShort: "sowa",
      extended: "System Obsługi Wniosków Administracyjnych",
    },
    shortNote:
      "Wspomaga działania urzędu w sprawnym realizowaniu wniosków, przeprowadzaniu postępowań i wydawaniu decyzji administracyjnych.",
    pic: {
      src: productsDiagrams.sowaPic,
      alt: "diagram sowa",
    },
    paragraphs: [
      "Integruje się z Systemami Obiegu Dokumentów",
      "Wspomaga pracę merytoryczną w sposób dostosowany do specyfiki poszczególnych wydziałów i referatów",
      "Ułatwia i przyspiesza proces wydawania decyzji",
      "Pozwala na bieżąco monitorować postęp załatwiania spraw",
      "Zapewnia możliwość integracji z innymi systemami i e-usługami",
      "Obsługuje lokalizowanie spraw na mapie miasta, wiążąc je z geometriami obiektów, których dotyczą",
      "Wizualizuje prowadzone sprawy na mapie",
      "Archiwizuje i udostępnia dokumenty w postaci cyfrowej",
    ],
  },
  {
    logo: {
      src: productsLogo.szopp,
      alt: "logo szopp",
    },
    fileName: "HyperView SZOPP.pdf",
    name: {
      short: "szopp",
      superShort: "szopp",
      extended: "System Zintegrowanej Obsługi Planowania Przestrzennego",
    },
    shortNote:
      "Unikatowe rozwiązanie w skali kraju. Automatyzuje proces opracowywania i publikowania planów zagospodarowania przestrzennego",
    pic: {
      src: productsDiagrams.szoppPic,
      alt: "diagram szopp",
    },
    paragraphs: [
      "Kompletny system informatyczny dla wydziałów Planowania Przestrzennego",
      "Wspomaga wiązanie elementów planów zagospodarowania przestrzennego z elementami uchwały",
      "Weryfikuje poprawność wprowadzonych powiązań",
      "Automatycznie modyfikuje wcześniej istniejące dane planistyczne przy zatwierdzaniu nowego planu, samodzielnie tworząc nowe wersje obiektów zmienianych przez uchwały i zapisując daty obowiązywania poszczególnych wersji",
      "Automatyzuje i upraszcza proces publikowania planów zagospodarowania w postaci wektorowej i rastrowej",
      "Generuje gotowe dokumenty wyrysów i wypisów",
      "Wyszukuje plany i strefy obowiązujące dla danej działki w podanym dniu",
    ],
  },
  {
    logo: {
      src: productsLogo.piece,
      alt: "logo piece",
    },
    fileName: "HyperView PIECE.pdf",
    name: {
      short: "piece",
      superShort: "piece",
      extended: "PIECE",
    },
    shortNote:
      "Pomaga walczyć ze smogiem. Wsparcie administracji w inwentaryzacji szkodliwych dla środowiska źródeł ciepła i obsłudze wniosków o dofinansowanie ich wymiany.",
    pic: {},
    paragraphs: [
      "Kompleksowo obsługuje programy dofinansowania wymiany źródeł ciepła",
      "Prowadzi ewidencję źródeł ciepła, lokalizuje je na mapie oraz wyznacza profil sposobów ogrzewania używanych przez mieszkańców",
      "Wspomaga obsługę wniosków o dofinansowanie wymiany źródeł ciepła",
      "Identyfikuje próby uzyskania wielokrotnego dofinansowania dla tego samego lokalu",
      "Drukuje gotowe umowy na podstawie dowolnych szablonów",
      "Generuje raporty pokazujące skuteczność realizacji programu dofinansowań oraz spadek liczby nieekologicznych źródeł ciepła",
    ],
  },
  {
    logo: {
      src: productsLogo.eoz,
      alt: "logo eoz",
    },
    fileName: "HyperView EOZ.pdf",
    name: {
      short: "eoz",
      superShort: "eoz",
      extended: "Ewidencja Obiektów Zabytkowych",
    },
    shortNote:
      "Pomaga dbać o dziedzictwo kulturowe. Wsparcie administracji w utrzymaniu obiektów zabytkowych i archeologicznych oraz cmentarzy.",
    pic: {},
    paragraphs: [
      "Umożliwia prowadzenie gminnej ewidencji zabytków, obiektów archeologicznych oraz cmentarzy",
      "Łączy obiekty z archiwum dokumentów i zdjęć",
      "Lokalizuje obiekty na konkretnych działkach i pod wskazanym adresem",
      "Przechowuje dane o charakterystycznych cechach zabytku oraz jego historii",
      "Generuje raporty i podsumowania",
    ],
  },
  {
    logo: {
      src: productsLogo.appPod,
      alt: "logo aplikacja podatkowa",
    },
    fileName: "HyperView Aplikacja Podatkowa.pdf",
    name: {
      short: "aplikacja podatkowa",
      superShort: "appPod",
      extended: "Aplikacja Podatkowa",
    },
    shortNote:
      "Realizuje analizy porównawcze w oparciu o bazy danych Ewidencji Gruntów i Budynków oraz Ewidencji Podatków od Nieruchomości. Wsparcie administracji w uszczelnieniu systemu poboru podatku od nieruchomości.",
    pic: {},
    paragraphs: [
      "Wykrywanie niespójności pomiędzy bazą danych Ewidencji Gruntów i Budynków oraz Ewidencją Podatkową Nieruchomości",
      "Generowanie raportów dla wyszukanych obiektów",
      "Wyświetlenie rejestru rozbieżności, w którym zawarte są wszystkie różnice między Ewidencjami",
      "Prezentowanie wyników w sposób tabelaryczny z możliwością wyświetlenia poszczególnych obiektów na mapie",
      "Eksport zestawień rozbieżności, w tym z poziomu mapy",
      "Logowanie dostępu do danych osobowych",
    ],
  },
];

export { products, constContent };
