import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const imgSettings = {
  className: "products__subtitle--logo",
  src: "../../assets/images/UrbanView_1059x204.png",
  alt: "logo UrbanView",
  placeholder: "blurred",
};

const PlatformLogo = () => <StaticImage {...imgSettings} />;

export default PlatformLogo;
