import hvEngOffer from "docs/offer/HV_offer_eng.pdf";
import hvPlOffer from "docs/offer/HV_offer_pl.pdf";
// ----------------------------------------------------------------------
import gpt from "docs/products/gpt.pdf";
import piece from "docs/products/piece.pdf";
import appPod from "docs/products/appPod.pdf";
import eoz from "docs/products/eoz.pdf";
import isdp from "docs/products/isdp.pdf";
import sowa from "docs/products/sowa.pdf";
import szopp from "docs/products/szopp.pdf";
// ----------------------------------------------------------------------
import aib from "docs/industries/aib.pdf";
import gik from "docs/industries/gik.pdf";
import gn from "docs/industries/gn.pdf";
import gp from "docs/industries/gp.pdf";
import pinb from "docs/industries/pinb.pdf";
import inw from "docs/industries/inw.pdf";

const offerDocs = { hvEngOffer, hvPlOffer };

const productsDocs = { gpt, piece, appPod, eoz, isdp, sowa, szopp };

const industriesDocs = { aib, gik, gn, gp, pinb, inw };

export { offerDocs, productsDocs, industriesDocs };
