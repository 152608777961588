import React, { useState } from "react";
import { Accordion } from "react-accessible-accordion";
import Product from "./Product";
import { AccordionNavItem } from "utils";
import { getAccordionItems } from "helpers/accordionNavItem.helpers";

const MobilePanel = ({ items, btnName }) => {
  const [isActiveItem, setIsActiveitem] = useState(getAccordionItems(items));

  const handleClick = item => {
    setIsActiveitem({ ...isActiveItem, [item]: !isActiveItem[item] });
  };

  const navItems = items.map((item, index) => (
    <AccordionNavItem
      key={index}
      name={item.name}
      pic={item.logo}
      onClick={handleClick}
      isActive={isActiveItem}
      product
    >
      <Product {...item} btnName={btnName} navIndex={index} />
    </AccordionNavItem>
  ));

  return (
    <Accordion allowMultipleExpanded allowZeroExpanded>
      {navItems}
    </Accordion>
  );
};

export default MobilePanel;
