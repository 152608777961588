import React from "react";

const DesktopNavItem = ({ logo, name, setActive }) => (
  <div
    className="products__nav-item"
    onKeyDown={() => setActive(name.superShort)}
    role="button"
    tabIndex="-1"
    onClick={() => setActive(name.superShort)}
  >
    <img
      loading="lazy"
      className="products__nav-img"
      src={logo.src}
      alt={logo.alt}
    />
    <p className="products__nav-name">{name.short}</p>
  </div>
);

export default DesktopNavItem;
