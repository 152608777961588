const getAccordionItems = items => {
  const arrayItems = items.map(item => item.name.short);

  return arrayItems.reduce((acc, cur) => {
    acc[cur] = false;
    return acc;
  }, {});
};

export { getAccordionItems };
