import React from "react";
import DesktopNavArrow from "./DesktopNavArrow";
import { constContent } from "staticContent/more/products";

const NavBtn = ({ next, onClick, text, right }) => (
  <div
    className={`product-block__nav-btn${
      !next ? "--hidden" : ""
    } product-block__nav-btn--${right ? "next" : "prev"}`}
    onClick={() => onClick(next)}
    onKeyDown={() => onClick(next)}
    tabIndex="0"
    role="button"
  >
    <p className={`nav-btn__text nav-btn__text--${right ? "next" : "prev"}`}>
      {text}
    </p>
    <DesktopNavArrow deg={right ? 270 : 90} />
  </div>
);

const DesktopNavBtns = ({ prev, next, goTo }) => (
  <div className="product-block__nav-btns-wrapper">
    <NavBtn next={prev} onClick={goTo} text={constContent.prev} />
    <NavBtn next={next} onClick={goTo} text={constContent.next} right />
  </div>
);

export default DesktopNavBtns;
