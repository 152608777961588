import React from "react";
import { Arrow } from "utils";

const DesktopNavArrow = ({ deg, size = 30, stroke = 14 }) => {
  const arrowPack = [];

  for (let i = 0; i <= 2; i++) {
    arrowPack.push(
      <Arrow
        key={deg + i}
        className={`nav-btn__arrow nav-btn__arrow--${
          deg < 180 ? "prev" : "next"
        }`}
        size={size}
        strokeWidth={stroke}
        rotateDeg={deg}
      />
    );
  }

  return arrowPack;
};

export default DesktopNavArrow;
