import React, { useRef } from "react";
import { Button, Check } from "utils";
import DesktopNavBtns from "./DesktopNavBtns";
import { products } from "staticContent/more/products";
import { productsDocs } from "routes/docsPaths";

const paragraphsList = list =>
  list.map((item, index) => (
    <li className="product-block__list-item" key={index}>
      <Check className="product-block__list-item--check" size={40} />
      {item}
    </li>
  ));

const Product = props => {
  const {
    logo,
    name,
    shortNote,
    pic,
    paragraphs,
    btnName,
    navIndex,
    handleClick,
  } = props;
  const prev = products[navIndex - 1]?.name.superShort;
  const next = products[navIndex + 1]?.name.superShort;
  const pageTop = useRef();

  const goToAnotherPage = page => {
    pageTop.current.scrollIntoView({ behavior: "smooth" });
    handleClick(page);
  };

  return (
    <div className="product-block" ref={pageTop}>
      <div className="product-block__name-wrapper">
        <div className="product-block__name-img-wrapper">
          <img
            className="product-block__name-img"
            src={logo.src}
            loading="lazy"
            alt={logo.alt}
          />
        </div>
        <h2 className="product-block__name-title">{name.short}</h2>
        <h3 className="product-block__name-subtitle">{name.extended}</h3>
      </div>
      <p className="product-block__short-text">{shortNote}</p>
      <div className="product-block__img-wrapper">
        <img
          className={`product-block__img${!pic.src ? "--hidden" : ""}`}
          src={pic.src}
          loading="lazy"
          alt={pic.alt}
        />
      </div>
      <ul className="product-block__list">{paragraphsList(paragraphs)}</ul>
      <a
        href={productsDocs[name.superShort]}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Button children={btnName} className="product-block__btn" />
      </a>
      <DesktopNavBtns prev={prev} next={next} goTo={goToAnotherPage} />
    </div>
  );
};

export default Product;
