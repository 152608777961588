import React, { useEffect, useRef, useState } from "react";
import { products, constContent } from "staticContent/more/products";
import Product from "./Product";
import SwitchComponents from "services/SwitchComponents";
import DesktopNavItem from "./DesktopNavItem";

const DesktopPanel = ({ product }) => {
  const [activeComponent, setActiveComponent] = useState("");
  const pageTop = useRef();

  useEffect(() => {
    product && setActiveComponent(product.substr(product.indexOf("#") + 1));
  }, [product]);

  useEffect(() => {
    pageTop.current && pageTop.current.scrollIntoView({ behavior: "smooth" });
  }, [activeComponent]);

  const productsNav = products.map(({ logo, name }) => (
    <DesktopNavItem
      key={name.short}
      logo={logo}
      name={name}
      setActive={setActiveComponent}
    />
  ));

  const productsCard = products.map((product, index) => (
    <Product
      key={product.name.short}
      {...product}
      btnName={constContent.btn}
      navIndex={index}
      handleClick={location => setActiveComponent(location)}
    />
  ));

  return (
    <>
      <nav className="products__nav-wrapper" ref={pageTop}>
        <div className="products__nav">{productsNav}</div>
      </nav>
      <section className="section">
        <SwitchComponents active={activeComponent || "gpt"}>
          {productsCard}
        </SwitchComponents>
      </section>
    </>
  );
};

export default DesktopPanel;
