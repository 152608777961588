import React, { useEffect, useState } from "react";
import PlatformLogo from "./PlatformLogo";
import MobilePanel from "./MobilePanel";
import DesktopPanel from "./DesktopPanel";
import { products, constContent } from "staticContent/more/products";

const Products = ({ product }) => {
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    setPageWidth(window.screen.width);
  }, []);

  return (
    <>
      <section className="section hero products">
        <h1 className="hero__banner-text">{constContent.pageTitle}</h1>
        <div className="products__subtitle">
          <h2 className="products__subtitle--text">
            {constContent.pageSubTitle}
          </h2>
          <PlatformLogo />
        </div>
      </section>
      {pageWidth < 600 ? (
        <MobilePanel items={products} btnName={constContent.btn} />
      ) : (
        <DesktopPanel product={product} />
      )}
    </>
  );
};

export default Products;
