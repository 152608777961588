import React from "react";
import Layout from "utils/Layout";
import Products from "components/Products";
import "styles/components/products.scss";
import { constContent } from "staticContent/more/products";

const IndexPage = ({ location: { hash } }) => (
  <Layout subTitle={constContent.pageTitle}>
    <Products product={hash} />
  </Layout>
);

export default IndexPage;
